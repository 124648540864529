import { isProduction } from 'app-config'

export enum FEATURE_TYPES {
  WPP_DUPLICATE_VERSION = 'WPP_DUPLICATE_VERSION',
  WPP_TEST_LAUNCH = 'WPP_TEST_LAUNCH',
}

type FEATURE_TYPES_NAMES = keyof typeof FEATURE_TYPES extends `WPP_${infer P}` ? P : never
export const featureFlags: Record<FEATURE_TYPES, `WPP_TEMPLATE_${FEATURE_TYPES_NAMES}`> = {
  WPP_DUPLICATE_VERSION: 'true',
  WPP_TEST_LAUNCH: 'true',
} as const

const featureFlagsConfig = Object.keys(FEATURE_TYPES).reduce((acc, type) => {
  const flag = isProduction ? featureFlags[type as FEATURE_TYPES] : process.env[`REACT_APP_${type}`]
  return { ...acc, [type]: flag }
}, {} as Record<FEATURE_TYPES, string>)

export default featureFlagsConfig
